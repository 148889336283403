<div class="container flex-fill">
    <div class="row align-items-center justify-content-xs-center justify-content-md-left">
        <div class="col-md-4">
            <img src="assets/about-photo-main.jpg" class="img-fluid" alt="Responsive image">
        </div>
        <div class="col-md-7 offset-md-1">
            <p class="lead" style="font-size: 30px;">Software developer and manager currently based out of the greater D.C. / Baltimore area.</p>
            <p class="" style="font-size: 20px;">Rookie husband, avid gamer, amateur chef, proud parent to two furry children.</p>
        </div>
    </div>
</div>