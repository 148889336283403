<div class="container-fluid bg-white sticky-top">
    <nav class="navbar navbar-expand-xs navbar-light mb-3 sticky-top">
        <a class="navbar-brand" [routerLink]="'.'"><span class="lead brand-spacing"><strong><strong>THURMAN
                        JUSTICE</strong></strong></span></a>

        <button class="navbar-toggler mr-5 pr-5" type="button" (click)="isMenuCollapsed = !isMenuCollapsed">
            &#9776;
        </button>

        <div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse">
            <ul class="navbar-nav mr-auto">
                <li class="nav-item active">
                        <a class="nav-link ml-auto" [routerLink]="'.'" (click)="isMenuCollapsed = true">Home</a>
                    
                </li>
                <li class="nav-item justify-content-end text-right">
                    <a class="nav-link text-right" [routerLink]="'about'" (click)="isMenuCollapsed = true">About</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" (click)="isMenuCollapsed = true"
                        href="/assets/Thurman Justice_Resume.pdf">Download Resume</a>
                </li>
            </ul>
        </div>
    </nav>
</div>