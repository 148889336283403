<div class="container-md pb-5">
    <div class="row">
        <div class="col-xs-12 col-md-5">
            <p class="lead text-left hero-description"><strong>Passionate, resourceful, and
                    results-driven
                    <strong>technology professional</strong> with 8 years healthcare IT experience.
                    Developing projects requiring excellent
                    design, coding, analysis, problem solving,
                    and implementation skills. </strong></p>
        </div>
    </div>
</div>
<div class="pt-5 pb-4 bg-app-grey">
    <div class="container">
    <div *ngFor="let s of developerSections" class="row mb-4">
        <div class="col-xs-12 col-md-2">
            <em>
                {{s.sectionHeader}}
                <hr class="w-50" />
            </em>
        </div>
        <div class="col-md-10 col-xs-12">
            <div class="row justify-content-center">
                <div class="col-xs-12 col-md-9">
                    <p *ngFor="let st of s.sectionTexts" class="lead"><strong [innerHtml]="st"></strong></p>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
<div class="container pt-4">
    <div class="row">
        <div class="col-md-3">
            <p class="lead text-left footer-text"><strong>
                    Let's Connect
                </strong></p>
                <hr/>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3">
            <p class="text-left">
                    I am always open to new challenges and opportunities.</p>
        </div>
    </div>
    <div class="row justify-content-start pb-5">
        <div class="col-md">
            <a class="btn btn-lg btn-block btn-outline-dark" href="/assets/Thurman Justice_Resume.pdf">Resume</a>&nbsp;
            <a class="btn btn-lg btn-block btn-outline-dark" href="mailto:thurman.justice@gmail.com">Email</a>
        </div>
    </div>
</div>