<app-nav></app-nav>
<div class="container-xs-fluid">
    <router-outlet></router-outlet>
</div>
<!-- footer -->
<footer class="footer">
<hr class="pb-0 mb-0"/>
<nav class="navbar navbar-light">
    <div class="container-fluid justify-content-between">
        <a class="navbar-brand" href="#">thurman.justice@gmail.com</a>
        <div>
        <a class="navbar-brand" href="https://github.com/thurman-justice"><img class="logo-sizing" src="/assets/GitHub-Mark-32px.png"/></a>
        <a class="navbar-brand" href="www.linkedin.com/in/thurman-justice-87190839"><img class="logo-sizing" src="/assets/linkedin-32.png"/></a>
        </div>
    </div>
</nav>
</footer>